import firebase from 'firebase';
import React from 'react';
import { Container } from 'react-bootstrap';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import logo from '../assets/images/logo.png';

const SignIn = () => {

  return (
    <Container className="align-items-center text-center flex-column">
      <img alt="" src={logo} width="140" className="mb-4" />
      <StyledFirebaseAuth
        uiConfig={{
          signInFlow: 'popup',
          signInSuccessUrl: '/',
          signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID
          ]
        }}
        firebaseAuth={firebase.auth()}
      />
    </Container>
  );
}

export default SignIn;