import useLocalStorage from '@rehooks/local-storage';
import firebase from 'firebase';
import React, { useContext } from 'react';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import { useCollection } from 'react-firebase-hooks/firestore';
import { isRightToLeft } from '../../constants';
import { translateContext } from '../../contexts';
import { IAppType, IQuestionBank, IQuestionBase, IQuestionOption, UseCollType } from '../../models';
import Loading from '../loading';

const TranslateQuestions = () => {

    const { language } = useContext(translateContext);
    const [appType] = useLocalStorage<IAppType | null>('appType');

    const questionBankColllName = `${appType?.toLowerCase()}-question-bank`;
    const translateCollPath = `${appType?.toLowerCase()}-translate/${String(language?.id)}/questions`;

    const [questionBankColl, questionBankLoading]: UseCollType = useCollection(
        firebase.firestore().collection(questionBankColllName)
    );

    const [translateColl, translateLoading]: UseCollType = useCollection(
        firebase.firestore().collection(translateCollPath)
    );

    if (translateLoading || questionBankLoading) {
        return <Loading />
    }


    if (!translateColl.empty) {

        return (
            <Container>
                {
                    questionBankColl.docs.map((doc, i) => {
                        const translateMatch = translateColl.docs.find(e => e.id === doc.id);
                        if (translateMatch) {
                            const original: IQuestionBank = doc.data() as IQuestionBank;
                            const translate: IQuestionBase = translateMatch.data() as IQuestionBase;
                            return (
                                <Row key={doc.id} className="mb-3 flex-column">
                                    <Form.Group as={Col} className="pt-3">
                                        <Form.Label><b>{i + 1})</b> {original.question} ({doc.id}) <b>{original.answer}</b></Form.Label>
                                        <Form.Control as={'textarea'} defaultValue={translate.question} dir={isRightToLeft(String(language?.id)) ? 'rtl' : ''}
                                            onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => updateQuestion(translateCollPath, doc.id, e.currentTarget.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label><i>{original.options[0].char})</i> {original.options[0].text}</Form.Label>
                                        <Form.Control as={'textarea'} defaultValue={translate.options[0].text} dir={isRightToLeft(String(language?.id)) ? 'rtl' : ''}
                                            onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => updateOption(translateCollPath, doc.id, translate.options, 0, e.currentTarget.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label><i>{original.options[1].char})</i> {original.options[1].text}</Form.Label>
                                        <Form.Control as={'textarea'} defaultValue={translate.options[1].text} dir={isRightToLeft(String(language?.id)) ? 'rtl' : ''}
                                            onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => updateOption(translateCollPath, doc.id, translate.options, 1, e.currentTarget.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label><i>{original.options[2].char})</i> {original.options[2].text}</Form.Label>
                                        <Form.Control as={'textarea'} defaultValue={translate.options[2].text} dir={isRightToLeft(String(language?.id)) ? 'rtl' : ''}
                                            onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => updateOption(translateCollPath, doc.id, translate.options, 2, e.currentTarget.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label><i>{original.options[3].char})</i> {original.options[3].text}</Form.Label>
                                        <Form.Control as={'textarea'} defaultValue={translate.options[3].text} dir={isRightToLeft(String(language?.id)) ? 'rtl' : ''}
                                            onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => updateOption(translateCollPath, doc.id, translate.options, 3, e.currentTarget.value)}
                                        />
                                    </Form.Group>
                                </Row>
                            )
                        } else {
                            return null;
                        }
                    })
                }
            </Container>
        )

    }

    return <Alert variant="dark">No data found!</Alert>;
}

const updateQuestion = (docPath: string, docId: string, value: string) => {
    firebase.firestore().doc(`${docPath}/${docId}`).update({ question: value });
}

const updateOption = (docPath: string, docId: string, options: IQuestionOption[], arrIndex: number, value: string) => {
    options[arrIndex].text = value;
    firebase.firestore().doc(`${docPath}/${docId}`).update({ options });
}

export default TranslateQuestions;
