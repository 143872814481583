import React, { useContext } from "react";
import { Button, Container } from "react-bootstrap";
import { userContext } from "../contexts";

const Unauthorised = () => {

    const { user, logout } = useContext(userContext);

    return (
        <Container>
            <h2>You don't have permission to see view this screen.</h2>
            {user?.email} <br />
            <Button variant="link" onClick={logout} className="p-0 text-danger">(log out)</Button>
        </Container>
    );
}

export default Unauthorised;