import firebase from "firebase";
import { createContext } from "react";
import { IAdmin, ILanguage, ITranslateContentType } from "./models";

export const userContext =
    createContext<{
        user?: firebase.User;
        logout?: () => void;
        admin?: IAdmin;
    }>({});

export const translateContext =
    createContext<{
        language?: ILanguage;
        changeLanguage?: (language: ILanguage) => void;
        contentType?: ITranslateContentType;
        changeContentType?: (contentType: ITranslateContentType) => void;
    }>({});

