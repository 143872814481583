import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { translateContext } from '../../contexts';
import { ILanguage, ITranslateContentType } from '../../models';
import TranslateExplanations from './translate-explanations';
import TranslateHeader from './translate-header';
import TranslateQuestions from './translate-questions';
import TranslateWords from './translate-words';

const Translate = () => {

  const [language, setLanguage] = useState<ILanguage>();
  const [contentType, setContentType] = useState<ITranslateContentType>();

  return (
    <translateContext.Provider value={{ language, contentType }}>
      <Container>
        <TranslateHeader
          changeLanguage={(value: ILanguage) => setLanguage(value)}
          changeContentType={(value: ITranslateContentType) => setContentType(value)}
        />
        <br />

        {(contentType === 'Questions') && <TranslateQuestions />}
        {(contentType === 'Explanations') && <TranslateExplanations />}
        {(contentType === 'Words') && <TranslateWords />}

      </Container>
    </translateContext.Provider>
  );
}

export default Translate;
