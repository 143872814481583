import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import React, { useContext } from 'react';
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from '../../assets/images/logo.png';
import { userContext } from '../../contexts';
import { IAppType } from '../../models';


const Header = () => {

    const { user, logout, admin } = useContext(userContext);
    const [appType] = useLocalStorage<IAppType | null>('appType');

    return (
        <Navbar bg="white" expand="md" fixed="top" className="border-bottom shadow-sm">
            <Container>
                <Navbar.Brand href="#home">
                    <img alt="" src={logo} height={32} className="d-inline-block align-top" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {admin &&
                        <Nav className="mr-auto" activeKey={appType}>
                            <NavDropdown title={!appType ? 'Apps' : appType} id="basic-nav-dropdown" onSelect={(key) => writeStorage('appType', key)}>
                                {admin.appCar && <NavDropdown.Item eventKey="Car">Car</NavDropdown.Item>}
                                {admin.appMotorcycle && <NavDropdown.Item eventKey="Motorcycle">Motorcycle</NavDropdown.Item>}
                                {admin.appADI && <NavDropdown.Item eventKey="ADI">ADI</NavDropdown.Item>}
                            </NavDropdown>
                        </Nav>
                    }
                    <Navbar.Text className="mr-2">{user?.email}</Navbar.Text>
                    <Navbar.Text>
                        <Button variant="link" onClick={logout} className="p-0 text-danger">(log out)</Button>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;