import useLocalStorage from "@rehooks/local-storage";
import firebase from "firebase";
import React, { useContext } from "react";
import { Button, ButtonGroup, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { useCollection } from "react-firebase-hooks/firestore";
import { translateContext } from "../../contexts";
import { IAppType, ILanguage, ITranslateContentType } from "../../models";

const TranslateHeader = (props: {
    changeLanguage: (language: ILanguage) => void,
    changeContentType: (contentType: ITranslateContentType) => void
}) => {

    const { language, contentType } = useContext(translateContext);
    const [appType] = useLocalStorage<IAppType | null>('appType');

    const collectionName = `${appType?.toLowerCase()}-languages`;
    const [languageColl, loading, error] = useCollection(
        firebase.firestore().collection(collectionName),
        { snapshotListenOptions: { includeMetadataChanges: true }, }
    );

    const contentTypes: ITranslateContentType[] = ['Questions', 'Explanations', 'Words'];

    return (
        <Container>
            <Row className="flex-center align-items-center">
                <h2>Translate</h2>
                {error && <strong>Languages error: {JSON.stringify(error)}</strong>}
                {loading && <span>loading...</span>}

                {languageColl &&
                    <DropdownButton variant="light" className="d-inline-block p-2"
                        key={language?.id} title={!language ? 'Languages' : language.name}
                        onSelect={(key, e) => {
                            const name = (e.currentTarget as HTMLAnchorElement).innerText;
                            props.changeLanguage({ id: String(key), name });
                        }}
                    >
                        {languageColl.docs.map((doc: any) => (
                            <Dropdown.Item key={doc.id} eventKey={doc.id} active={language === doc.id}>
                                {doc.data().name}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                }
            </Row>
            <Row>


                {language &&
                    <ButtonGroup className="justify-content-right">
                        {contentTypes.map((item: ITranslateContentType, i: number) => (
                            <Button key={i} onClick={() => props.changeContentType(item)} variant="outline-info" active={contentType === item}>{item}</Button>
                        ))}
                    </ButtonGroup>
                }
            </Row>
        </Container>
    );
}



export default TranslateHeader;