import useLocalStorage from '@rehooks/local-storage';
import firebase from 'firebase';
import React, { useContext } from 'react';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import { useCollection } from 'react-firebase-hooks/firestore';
import { isRightToLeft } from '../../constants';
import { translateContext } from '../../contexts';
import { IAppType, IQuestionBank, IQuestionExplanation, UseCollType } from '../../models';
import Loading from '../loading';

const TranslateExplanations = () => {

    const { language } = useContext(translateContext);
    const [appType] = useLocalStorage<IAppType | null>('appType');

    const questionBankColllName = `${appType?.toLowerCase()}-question-bank`;
    const translateCollPath = `${appType?.toLowerCase()}-translate/${language?.id}/explanations`;

    const [questionBankColl, questionBankLoading]: UseCollType = useCollection(
        firebase.firestore().collection(questionBankColllName)
    );

    const [translateColl, translateLoading]: UseCollType = useCollection(
        firebase.firestore().collection(translateCollPath)
    );

    if (translateLoading || questionBankLoading) {
        return <Loading />
    }


    if (!translateColl.empty) {

        return (
            <Container className="">
                {
                    questionBankColl.docs.map((doc, i) => {
                        const translateMatch = translateColl.docs.find(e => e.id === doc.id);
                        if (translateMatch) {
                            const original: IQuestionBank = doc.data() as IQuestionBank;
                            const translate: IQuestionExplanation = translateMatch.data() as IQuestionExplanation;
                            return (
                                <Row key={doc.id} id={i.toString()} className="mb-3 flex-column">
                                    <Form.Group as={Col} className="pt-3">
                                        <Form.Label><b>{(i + 1).toString()})</b> {original.explanation} ({doc.id})</Form.Label>
                                        <Form.Control as={'textarea'} defaultValue={translate.content} style={{ height: '140px' }} dir={isRightToLeft(String(language?.id)) ? 'rtl' : ''}
                                            onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => update(translateCollPath, doc.id, e.currentTarget.value)}
                                        />
                                    </Form.Group>
                                </Row>
                            )
                        } else {
                            return null;
                        }
                    })
                }
            </Container>
        )

    }

    return <Alert variant="dark">No data found!</Alert>;
}

const update = (docPath: string, docId: string, value: string) => {
    firebase.firestore().doc(`${docPath}/${docId}`).update({ content: value });
}

export default TranslateExplanations;
