import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseConfig } from './constants';
import Home from './home/home';
import SignIn from './sign-in/sign-in';

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
    firebase.firestore();
}

const App = () => {

    const [user, loading, error] = useAuthState(firebase.auth());

    if (loading) {
        return <Container>Initialising user...</Container>;
    }

    if (error) {
        return <Container>Error: {error}</Container>;
    }

    if (user) {
        return (<Home user={user} />);
    }

    return <SignIn />;

}

export default App;