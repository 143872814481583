import { useLocalStorage } from '@rehooks/local-storage';
import firebase from 'firebase/app';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { userContext } from '../contexts';
import { IAdmin, IAppType } from '../models';
import Header from './header/header';
import Translate from './translate/translate';
import Unauthorised from './unauthorised';

const Home = (props: { user: firebase.User }) => {

  const [appType] = useLocalStorage<IAppType | null>('appType');

  const [admin, loading] = useDocumentData<IAdmin>(
    firebase.firestore().doc(`admin-users/${props.user.email}`), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  if (loading) {
    return <Container>Checking permissons...</Container>;
  }

  if (!admin) {
    return <Unauthorised />;
  }

  return (
    <userContext.Provider value={{ user: props.user, logout, admin }}>
      <Router>
        <Header></Header>
        {appType &&
          <Switch>
            <Route path="/"> <Translate /> </Route>
          </Switch>
        }
      </Router >
    </userContext.Provider>
  );

}

const logout = () => {
  firebase.auth().signOut();
}

export default Home;
