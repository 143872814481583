import useLocalStorage from '@rehooks/local-storage';
import firebase from 'firebase';
import React, { useContext } from 'react';
import { Alert, Container, Form, Row } from 'react-bootstrap';
import { useCollection } from 'react-firebase-hooks/firestore';
import { isRightToLeft } from '../../constants';
import { translateContext } from '../../contexts';
import { IAppType, ITranslateWord, UseCollType } from '../../models';
import Loading from '../loading';

const TranslateWords = () => {

    const { language } = useContext(translateContext);
    const [appType] = useLocalStorage<IAppType | null>('appType');

    const questionBankColllName = `${appType?.toLowerCase()}-question-bank`;
    const translateCollPath = `${appType?.toLowerCase()}-translate/${language?.id}/words`;

    const [questionBankColl, questionBankLoading]: UseCollType = useCollection(
        firebase.firestore().collection(questionBankColllName)
    );

    const [translateColl, translateLoading]: UseCollType = useCollection(
        firebase.firestore().collection(translateCollPath)
    );

    if (translateLoading || questionBankLoading) {
        return <Loading />
    }


    if (!translateColl.empty) {
        return (
            <Container>
                <Form>
                    {
                        questionBankColl.docs.map((doc, i) => {
                            const translateMatch = translateColl.docs.find(e => e.id === doc.id);
                            if (translateMatch) {
                                const original: string[] = (translateMatch.data() as ITranslateWord).original;
                                const translate: string[] = (translateMatch.data() as ITranslateWord).translated;
                                return (
                                    <Row key={i} id={i.toString()} className="mb-3 border-bottom">
                                        <Form.Group key={i} className="word-wrapper mr-4">
                                            <Form.Label><b>{i + 1})</b></Form.Label><br />
                                            <Form.Label className="opac">{doc.id}</Form.Label>
                                        </Form.Group>
                                        {original.map((item, j) => {
                                            return (
                                                <Form.Group key={j} controlId={doc.id + '_' + j.toString()} className="word-wrapper mr-4">
                                                    <Form.Label>{item}</Form.Label>
                                                    <Form.Control type="text" defaultValue={translate[j]} width="100"
                                                        dir={isRightToLeft(String(language?.id)) ? 'rtl' : ''}
                                                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => update(translateCollPath, doc.id, translate, j, e)} />
                                                </Form.Group>
                                            );
                                        })}
                                    </Row>
                                )
                            } else {
                                return null;
                            }
                        })
                    }
                </Form>
            </Container>
        )
    }

    return <Alert variant="dark">No data found!</Alert>;
}

const update = (docPath: string, docId: string, arrCurr: string[], index: number, event: React.FocusEvent<HTMLInputElement>) => {
    arrCurr[index] = event.currentTarget.value;
    firebase.firestore().doc(`${docPath}/${docId}`).update(
        { translated: arrCurr }
    );
}

export default TranslateWords;
