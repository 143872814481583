export const firebaseConfig = {
    apiKey: "AIzaSyCESgycoXMvmCMjjTBQckwk0FcmUkmc_kk",
    authDomain: "drivingo-theory-test.firebaseapp.com",
    databaseURL: "https://drivingo-theory-test.firebaseio.com",
    projectId: "drivingo-theory-test",
    storageBucket: "drivingo-theory-test.appspot.com",
    messagingSenderId: "1034595736824",
    appId: "1:1034595736824:web:8655b775adcac4e75e2f0d",
    measurementId: "G-EPWHMDCKPP"
};

export const isRightToLeft = (language: string) => {
    const languages = ['ar', 'fa', 'ur'];
    return (languages.indexOf(language) !== -1);
}